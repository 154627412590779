/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import ToggleAnalytics from "./src/utils/Analytics"

// You can delete this file if you're not using it
import "./src/styles/global.css"

// Called when the initial (but not subsequent) render of Gatsby App is done on the client.
export const onInitialClientRender = () => {
  const loader = document.getElementById("___loader")
  const gatsby = document.getElementById("___gatsby")
  const body = document.getElementById("body")

  setTimeout(() => {
    if (gatsby && loader) {
      gatsby.style.display = "block"
      loader.style.opacity = 0
    }

    setTimeout(() => {
      if (body && gatsby && loader) {
        gatsby.style.opacity = 1
        loader.style.display = "none"
      }
    }, 1000)
  }, 1000)
}

export const onRouteUpdate = () => {
  const loader = document.getElementById("___loader")
  const gatsby = document.getElementById("___gatsby")
  const body = document.getElementById("body")

  ToggleAnalytics()

  //  alert("OKAY")

  //  loader.style.opacity = 1

  /*
  setTimeout(() => {
    if (gatsby && loader) {
      gatsby.style.display = "block"
      loader.style.opacity = 0
    }

    setTimeout(() => {
      if (body && gatsby && loader) {
        body.style.overflow = "auto"
        gatsby.style.opacity = 1
        loader.style.display = "none"
      }
    }, 1000)
  }, 1000)
  */
}

/*
// Called when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

// Called when changing location is longer than 1 second.
exports.onRouteUpdateDelayed = () => {
  console.log("We can show loading indicator now")
}

*/
